import { Box, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material'
import { useMap } from '@vis.gl/react-google-maps'
import { useEffect, useState } from 'react'
import { render } from 'react-dom'

const controls = [
    { id: 1, name: 'Түгээх байршлууд' },
    { id: 2, name: 'Явсан зам' }
]

function ControlPanelBox({ delmanNames, selectedDelman, selectDelman, checked, setChecked }) {
    const handleCheck = (id) => { 
        setChecked(prev => {
            if (prev.includes(id)) return prev.filter(p => p !== id)
            return [...prev, id]
        })
    }

    const handleChange = (e) => { selectDelman(e.target.value) }
    //console.log("V: ", selectedDelman)

    return (
        <Box 
            sx={{
                boxShadow: '0 2px 10px #000',
                backgroundColor: 'white', 
                borderRadius: '5px',
                p: '10px', 
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                <Typography sx={{ fontSize: '13px', mr: 1 }}>Түгээгч:</Typography>
                <Select
                    id="demo-simple-select"
                    value={selectedDelman !== null ? selectedDelman : ''}
                    onChange={handleChange}
                    displayEmpty
                    sx={{ '& .MuiSelect-select': { padding: '2px' } }}
                >
                    <MenuItem value={''}>
                        <Typography sx={{ fontSize: '13px' }}>Бүгд</Typography>
                    </MenuItem>
                    {
                        delmanNames?.map(d => (
                            <MenuItem key={d.id} value={d.id}>
                                <Typography sx={{ fontSize: '13px' }}>{d.name}</Typography>
                            </MenuItem>
                        ))
                    }
                </Select>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                {
                    controls.map(c => (
                        <FormControlLabel
                            key={c.id}
                            control={
                                <Checkbox
                                    checked={checked?.includes(c.id)}
                                    onChange={() => handleCheck(c.id)}
                                    size='small'
                                    sx={{ p: '2px' }}
                                />
                            }
                            label={<Typography sx={{ fontSize: '13px' }}>{c.name}</Typography>}
                        />
                    ))
                }
            </Box>
        </Box>
    )
}

export default function ControlPanel({ delmanNames, selectedDelman, selectDelman, checked, setChecked }) {
    const map = useMap()
    const [controlContainer, setControlContainer] = useState(null)

    useEffect(() => {
        if (map) {
            const controlDiv = document.createElement('div')
            controlDiv.style.margin = '10px'
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(controlDiv)
            setControlContainer(controlDiv)
        }
    }, [map])

    if (!controlContainer) return null
    return render(
        <ControlPanelBox 
            delmanNames={delmanNames}
            selectedDelman={selectedDelman}
            selectDelman={selectDelman}
            checked={checked}
            setChecked={setChecked}
        />,
        controlContainer
    )
}

