import { LogoutOutlined } from '@mui/icons-material'
import { removeTokens } from '../../utils/functions'
import { Axios } from '../../global/axiosBase'
import MyTooltip from '../helpers/MyTooltip'
import { Button } from '@mui/material'

export default function Logout() {
    const handleLogout = () => {
        Axios.post('auth/logout/')
            .then(_ => { console.log("logout success") })
            .catch(e => console.log("logout err: ", e))
            .finally(() => {
                removeTokens()
                window.location.href = '/signin'
            })
    }

    return (
        <MyTooltip title='Гарах' X={40}>
            <Button onClick={() => handleLogout()} variant='contained'>
                <LogoutOutlined />
            </Button>
        </MyTooltip>
    )
}