import { Box, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { updateShipItemQty } from '../../../../../../../../../api/seller'
import { scrollStyle } from '../../../../../../../../../utils/constants'
import { toPrice } from '../../../../../../../../../utils/functions'
import { useShipCtx } from '../../../../..'
import { Close } from '@mui/icons-material'
import OrderItem from './OrderItem'
import { useState } from 'react'

const itemCols = ['№', 'Барааны нэр', 'Үнэ', 'Тоо ширхэг', 'Нийт үнэ']
export default function OrderItems({ items, disabled, handleClose }) {
    const { setMsg, isDelman } = useShipCtx()
    const [orderItems, setOrderItems] = useState(items||[])
    const { totalPrice, totalCount } = orderItems?.reduce((acc, cur) => ({ totalPrice: acc.totalPrice + cur.itemPrice * cur.itemQty, totalCount: acc.totalCount + cur.itemQty  }), { totalPrice: 0, totalCount: 0 })
    const updateOrderItemQty = (itemId, qty) => {
        updateShipItemQty({ itemId, qty })
            .then(res => {
                if (res?.data) {
                    const { itemQty, itemTotalPrice } = res.data
                    setOrderItems(prev => prev.map(item => item.itemId === itemId ? { ...item, itemQty, itemTotalPrice } : item ))
                }
            })
            .catch(e => { console.log("ERR: ", e); setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
    }

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogContent sx={{ p: 1, pt: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '14px', ml: 1 }}>Захиалгын бараанууд</Typography>
                    <IconButton onClick={handleClose}>
                        <Close sx={{ fontSize: '18px' }} />
                    </IconButton>
                </Box>
                <TableContainer
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '350px',
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 8 },
                        mb: 1,
                        p: 0,
                        pr: '4px',
                        boxShadow: 3,
                    }}
                >
                    <Table 
                        sx={{
                            m: 0, 
                            mb: 2,
                            p: '2px',
                            '& > thead > tr > th': { textAlign: 'center', bgcolor: 'secondary.light', color: 'white' },
                        }}
                        stickyHeader
                    >
                        <TableHead>
                            <TableRow sx={{ '& > th': { fontWeight: 600, p: '0px 3px', textAlign: 'center', width: 'fit-content' } }}>
                                {itemCols.map(c => <TableCell sx={{ textWrap: 'nowrap' }}>{c}</TableCell>) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orderItems?.map(
                                    (item, idx) => 
                                        <OrderItem 
                                            key={item.itemId} 
                                            idx={idx} 
                                            item={item} 
                                            disabled={disabled} 
                                            toPrice={toPrice}
                                            setOrderItems={setOrderItems}
                                            updateOrderItemQty={updateOrderItemQty}
                                            isDelman={isDelman}
                                        />
                                )
                            }
                            {
                                items?.length > 1 &&
                                <TableRow 
                                    sx={{ 
                                        '& > td': { 
                                            textAlign: 'center', 
                                            fontWeight: 600, 
                                            p: '1px 3px', 
                                            color: 'white',
                                            bgcolor: 'secondary.light', 
                                        },
                                        position: 'sticky', 
                                        bottom: 0 
                                    }}
                                >
                                    <TableCell colSpan={2} sx={{ borderBottomColor: 'transparent', bgcolor: 'transparent !important', }}></TableCell>
                                    <TableCell sx={{ textAlign: 'right !important', fontWeight: 600 }}>Нийт: </TableCell>
                                    <TableCell sx={{ fontWeight: 600  }}>{totalCount??''}</TableCell>
                                    <TableCell sx={{ fontWeight: 600, textWrap: 'nowrap'  }}>{totalPrice ? toPrice(totalPrice) : ''}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    )
}
