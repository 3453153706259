
function getBrowserInfo() {
    const userAgent = navigator.userAgent
    let browser = null
    let version = null
    if (userAgent?.indexOf("Firefox") > -1) {
        browser = "Mozilla Firefox"
        version = userAgent?.match(/Firefox\/[\d.]+/)?.[0]?.split("/")?.[1]
    } else if (userAgent?.indexOf("Opera") > -1 || userAgent?.indexOf("OPR") > -1) {
        browser = "Opera"
        version = userAgent?.match(/(Opera|OPR)\/[\d.]+/)?.[0]?.split("/")?.[1]
    } else if (userAgent?.indexOf("Chrome") > -1 && userAgent?.indexOf("Edg") === -1) {
        browser = "Google Chrome"
        version = userAgent?.match(/Chrome\/[\d.]+/)?.[0]?.split("/")?.[1]
    } else if (userAgent?.indexOf("Edg") > -1) {
        browser = "Microsoft Edge"
        version = userAgent?.match(/Edg\/[\d.]+/)?.[0]?.split("/")?.[1]
    } else if (userAgent?.indexOf("Safari") > -1 && userAgent?.indexOf("Chrome") === -1) {
        browser = "Apple Safari"
        version = userAgent?.match(/Version\/[\d.]+/)?.[0]?.split("/")?.[1]
    } else if (userAgent?.indexOf("MSIE") > -1 || !!document.documentMode) {
        browser = "Microsoft Internet Explorer"
        version = userAgent?.match(/MSIE [\d.]+/)?.[0]?.split(" ")?.[1]
    }
    return { browser, version }
}

function getOSInfo() {
    const userAgent = navigator.userAgent;
    let osName = null;
    let osVersion = null;
  
    // Check for Windows
    if (userAgent.indexOf("Windows NT 10.0") !== -1) {
        osName = "Windows";
        osVersion = "10";
    } else if (userAgent.indexOf("Windows NT 6.3") !== -1) {
        osName = "Windows";
        osVersion = "8.1";
    } else if (userAgent.indexOf("Windows NT 6.2") !== -1) {
        osName = "Windows";
        osVersion = "8";
    } else if (userAgent.indexOf("Windows NT 6.1") !== -1) {
        osName = "Windows";
        osVersion = "7";
    } else if (userAgent.indexOf("Windows NT 6.0") !== -1) {
        osName = "Windows";
        osVersion = "Vista";
    } else if (userAgent.indexOf("Windows NT 5.1") !== -1 || userAgent.indexOf("Windows XP") !== -1) {
        osName = "Windows";
        osVersion = "XP";
    }
  
    // Check for macOS/iOS
    else if (userAgent.indexOf("Mac OS X") !== -1) {
        osName = "macOS";
        osVersion = userAgent.match(/Mac OS X ([\d_]+)/)[1].replace(/_/g, ".");
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        osName = "iOS";
        osVersion = userAgent.match(/OS ([\d_]+)/)[1].replace(/_/g, ".");
    }
  
    // Check for Android
    else if (userAgent.indexOf("Android") !== -1) {
        osName = "Android";
        osVersion = userAgent.match(/Android ([\d.]+)/)[1];
    }
  
    // Check for Linux
    else if (userAgent.indexOf("Linux") !== -1) {
        osName = "Linux";
        // Some Linux distributions (like Ubuntu) provide version info in the user agent
        if (userAgent.indexOf("Ubuntu") !== -1) {
            osVersion = userAgent.match(/Ubuntu\/([\d.]+)/) ? userAgent.match(/Ubuntu\/([\d.]+)/)[1] : "Unknown Version";
        }
    }
    return { osName, osVersion }
}
console.log(getOSInfo());
  
export function getDeviceInfo() {
    return {
        ram: navigator.deviceMemory,
        cpu: navigator.hardwareConcurrency,
        sw: window.screen.width,
        sh: window.screen.height,
        ...getBrowserInfo(),
        ...getOSInfo()
    }
}
