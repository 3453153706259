import { Box, InputAdornment, MenuItem, TableCell, TextField } from '@mui/material'
import { primaryLight } from '../../../../../../utils/constants'
import DropMenu from '../../../../../helpers/DropMenu'
import { Close } from '@mui/icons-material'
import { useRef, useState } from 'react'
import { keys } from 'underscore'

const choiceFields = {
    partner__cType: {
        P: 'Эмийн сан',
        S: 'Нийлүүлэгч'
    },
    isBad: {
        true: 'Найдваргүй',
        false: 'Найдвартай'
    }
}

export default function ChoiceFieldFilter({ fieldName, setNewValue }) {
    const choices = choiceFields?.[fieldName]
    const [value, setValue] = useState(null)
    const handleSelect = (k) => {
        if (keys(choices).includes(k)) {
            setValue(k)
            setNewValue({ fieldName, newValue: k })
        }
    }
    const handleClear = () => {
        setValue(null)
        setNewValue({ fieldName, newValue: null })
    }

    const rf = useRef(null)

    return (
        <TableCell>
            <DropMenu
                title={<Box sx={{ display: 'flex', flexDirection: 'row', p: '4px' }}>
                            <TextField
                                ref={rf}
                                placeholder={choices?.[value]??'Бүгд'}
                                InputProps={{
                                    endAdornment: (<InputAdornment>
                                                        <Close
                                                            sx={{ 
                                                                fontSize: '18px', color: primaryLight,
                                                                '&:hover': { cursor: 'pointer' },
                                                                visibility: Boolean(value) ? 'visible' : 'hidden'
                                                            }}
                                                            onClick={handleClear}
                                                        />
                                                    </InputAdornment>)
                                }}
                                inputProps={{ style: { padding: '2px 5px', fontSize: '14px', cursor: 'pointer' } }}
                                sx={{
                                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: primaryLight },
                                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '14px', opacity: 1, ml: '5px' },
                                    '& .MuiInputBase-input': { 'caretColor': 'transparent' }
                                }}
                                size='small'
                            />
                        </Box>}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                            {keys(choices).map(k => <MenuItem key={k} sx={{ fontSize: '14px', color: primaryLight, width: rf?.current?.clientWidth  }} onClick={() => handleSelect(k)}>
                                                        {choices[k]}
                                                    </MenuItem>)}
                            </Box>}
                left={4}
                top={32}
            />
        </TableCell>
    )
}
