import { removeTokens } from '../../../../utils/functions'
import { useAuthCtx } from '../../../../context/AuthCtx'
import { Axios } from '../../../../global/axiosBase'
import { Button } from '@mui/material'

export default function LogOut () {
    const { clearUserItems } = useAuthCtx()

    const handleLogout = () => {
        Axios.post('auth/logout/')
            .then(_ => {
                removeTokens()
                clearUserItems()
                window.location.href = '/signin'
            })
            .catch(e => console.log(e))
    }

    return (
        <Button 
            onClick={() => handleLogout()} 
            sx={{
                color: 'white', p: 0, borderBottom: '1px solid transparent', m: 0,
				'&:hover': { borderBottomColor: 'white', borderRadius: 0 }
            }}
        >Гарах</Button>
    )
}

