import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState, useRef, createContext, useContext } from "react"
import { scrollStyle } from "../../../../utils/constants"
import { getPartners } from "../../../../api/company"
import TablePages from "../../../common/TablePages"
import { isEmpty, keys } from "underscore"
import HeadCell from "./Cells/HeadCell"
import { useQuery } from "react-query"
import FilterRow from "./Filter"
import Row from "./Row"

const CustomerListCtx = createContext()
export const useCustomerListCtx = () => useContext(CustomerListCtx)
export default function Customers () {
	const [columns, setColumns] = useState({
		name: { label: "Нэр", order: "", align: "center" },
		rd: { label: "РД", order: "", align: "center" },
		debtLimit: { label: "Зээлийн лимит", order: "", align: "center" },
		debt: { label: "Нийт өр", order: "", align: "center" },
		debtCnt: { label: "Төлөгдөөгүй захиалгын тоо", order: "", align: "center" },
		orderCnt: { label: "Нийт захиалгын тоо", order: "", align: "center" },
		orderSum: { label: "Нийт захиалгын дүн", order: "", align: "center" },
		email: { label: "И-мэйл", order: "", align: "center" },
		phone: { label: "Утасны дугаар", order: "", align: "center" },
		isBad: { label: "Найдваргүй эсэх", order: "", align: "center" },
	})
	// ordering
	const handleOrder = (fieldName) => {
		const c = columns?.[fieldName]
		const v =
			c?.order === ""
				?	fieldName
				:	c?.order === fieldName
						?	`-${fieldName}`
						:	""
		setColumns({
				...columns,
				[fieldName]: { ...c, order: v },
			})
	}
	const orderParam = keys(columns)?.reduce((acc, k) => {
	const cur = columns?.[k]?.order;
		if (acc !== "ordering=" && cur !== "") {
			acc += ","
		}
		if (cur !== "") {
			acc += `${cur}`
		}
		return acc
	}, "ordering=")

	// pagination
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(20)
	const [filtering, setFiltering] = useState(null)
	const filterParam = keys(filtering)?.reduce((acc, k) => {
		acc += acc === "" ? `${k}${filtering[k]}` : `&${k}${filtering[k]}`
		return acc
	}, "")

	const [partners, setPartners] = useState([])
	const [count, setCount] = useState(0)
	const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
	// fetch data
	const { data } = useQuery(["get_partners", page, pageSize, orderParam, filterParam], getPartners, { onSuccess: getData })
	useEffect(() => {
		if (!isEmpty(data?.data)) {
			setPartners(data?.data?.results || [])
		} else {
			setPartners([])
		}
	}, [data?.data])
	const hhRef = useRef(null)
	console.log("P: ", data)

	return (
		<>
			<TableContainer
				sx={{
					...scrollStyle,
					"&::-webkit-scrollbar": { width: 8, height: 8 },
				}}
			>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& > th": {
									fontSize: "12px",
									backgroundColor: "secondary.light",
									color: "white",
									borderRight: "1px solid white",
									p: "4px 4px",
									textAlign: "center",
								},
							}}
							ref={hhRef}
						>
							<TableCell>
								<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
									№
								</Typography>
							</TableCell>
							{
								keys(columns)?.map((c) => (
									<HeadCell
										key={c}
										field={columns?.[c]}
										fieldName={c}
										handleOrder={handleOrder}
									/>
								))
							}
						</TableRow>
					</TableHead>
					<FilterRow filtering={filtering} setFiltering={setFiltering} />
					<TableBody>
						{
							partners?.map((row, idx) => (
								<Row
									key={`${idx}`}
									row={row}
									setPartners={setPartners}
									idx={idx}
									page={page}
									pageSize={pageSize}
								/>
							))
						}
					</TableBody>
					<TablePages
						count={count}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						colSpan={keys(columns)?.length + 1}
					/>
				</Table>
			</TableContainer>
		</>
	)
}