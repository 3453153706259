import { Avatar, Box, ListItem, ListItemText, TextField } from '@mui/material'
import { PROCESS_COLORS } from '../../constants'


const getRandomIdx = (arrLength) => Math.floor(Math.random() * arrLength)

export default function Orderer({ pharmas }) {
    const pharmacy = null
    const colors = PROCESS_COLORS.map(pc => pc.color)

    return (
        <Box sx={{ mt: 15 }}>
            <TextField
                id="select_user"
                select
                label="Захиалагч сонгох"
                defaultValue={pharmacy}
                helperText="Захиалагч сонгоно уу!"
            >
                {pharmas.map((p) => (
                    <ListItem key={p.id}>
                        <Avatar sx={{ mr: 1, bgcolor: colors[getRandomIdx(colors.length)] }}>{p.name?.charAt(0)}</Avatar>
                        <ListItemText>{p.name}</ListItemText>
                    </ListItem>
                ))}
            </TextField>
        </Box>
    )
}
