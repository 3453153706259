import { createContext, useContext, useState } from 'react'
import { Box, Container, Tab, Tabs } from '@mui/material'
import { getToast } from '../auth/helpers/ToastAlert'
import { isTokenValid } from '../../utils/functions'
import { paperStyle } from '../../utils/constants'
import { Outlet, useNavigate } from 'react-router'
import { useAuthCtx } from '../../context/AuthCtx'
import { isEmpty, keys } from 'underscore'
import Top from './Top'

const tabNames = {
	'orders': 'Захиалга',
	'branch': 'Салбар',
	'templets': 'Хадгалсан сагс',
	'settings': 'Тохиргоо',
}

const ProfileCtx = createContext()
export const useProfileCtx = () => useContext(ProfileCtx)
const Profile = () => {
	const { navBtm } = useAuthCtx()
	const { role } = isTokenValid()
	
	const navi = useNavigate()
	const [cur, setCur] = useState('orders')
	
	const handleTabChange = (_, newValue) => {
		setCur(newValue)
		if (newValue === 'orders') {
			navi('/profile/orders')
		} else if (newValue === 'branch') {
			navi('/profile/branch')
		} else if (newValue === 'templets') {
			navi('/profile/templets')
		} else if (newValue === 'settings') {
			navi('/profile/settings')
		}
	}

	const staffTabs = ['orders', 'templets', 'settings']

	const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }

	return (
		<ProfileCtx.Provider
			value={{ msg, setMsg }}
		>
			<Container maxWidth='xl' sx={{ p: 0 }}>
				{ getToast(!isEmpty(msg), msgOff, msg?.m, msg?.s, 1500) }
				<Box sx={{ height: `calc(${navBtm}px + 10px)` }}></Box>
				{
					<Box sx={{ width: '100%', m: 0 }}>
						<Top />
						<Box sx={{ ...paperStyle, display: 'flex', flexDirection: 'row', minHeight: '600px' }}>
							<Tabs 
								value={cur} onChange={ handleTabChange }
								sx={{ borderRight: 1, borderColor: 'primary.main' }}
								orientation='vertical' centered={false}
							>
								{
									keys(tabNames).filter(t => role === 'PM' ? staffTabs.includes(t) : t)
										.map(t => <Tab key={`k${t}`} value={t} label={tabNames[t]} sx={{ borderBottom: 1 }} />)
								}
							</Tabs>
							<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
								<Outlet />
							</Box>
						</Box>
					</Box>
				}
			</Container>
		</ProfileCtx.Provider>
  )
}

export default Profile