import { InputAdornment, TableCell, TextField } from "@mui/material"
import { primaryLight } from "../../../../../../utils/constants"
import { Close } from "@mui/icons-material"
import { useState } from "react"

export default function TextFieldFilter({ fieldName, setNewValue }) {
  const [value, setValue] = useState("");
  const handleValue = (e) => {
    const v = e.target.value;
    if (v) {
      setValue(v);
    } else {
      setValue("");
      setNewValue({ fieldName, newValue: v });
    }
  };
  const clearValue = () => {
    setValue("");
    setNewValue({ fieldName, newValue: null });
  };
  const handleEnter = (e) => {
    if (e.code === "Enter") {
      setNewValue({ fieldName, newValue: value });
    }
  };

  return (
    <TableCell>
      <TextField
        value={value}
        onChange={handleValue}
        onKeyDown={handleEnter}
        placeholder={"Бүгд"}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <Close
                sx={{
                  fontSize: "18px",
                  color: primaryLight,
                  "&:hover": { cursor: "pointer" },
                  visibility: Boolean(value) ? "visible" : "hidden",
                }}
                onClick={clearValue}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{ style: { padding: "2px 5px", fontSize: "14px" } }}
        sx={{
          "& fieldset": { borderColor: primaryLight, borderRadius: 0 },
          "&:hover fieldset": { borderColor: primaryLight },
          "& .MuiInputBase-input::placeholder": {
            color: primaryLight,
            fontSize: "14px",
            opacity: 1,
            ml: "5px",
          },
        }}
        size="small"
      />
    </TableCell>
  );
}
