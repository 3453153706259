import {TableCell, Typography } from "@mui/material";

export default function HeadCell({ field, fieldName, handleOrder }) {
  return (
    <TableCell align={field?.align} width={field?.width}>
      <Typography fontSize="13px" fontWeight="bold">
        {field?.label}{" "}
      </Typography>
      {/* <IconButton
                onClick={() => handleOrder(fieldName)}
                sx={{ p: 0, textAlign: 'center' }}
            >
                {
                    field?.order === ''
                        ?   <Height sx={{ fontSize: '18px', color: 'white' }} />
                        :   <South sx={{ fontSize: '18px', color: 'white', transform: field?.order === fieldName ? 'none' : 'rotateX(180deg)' }} />
                }
            </IconButton> */}
    </TableCell>
  );
}
