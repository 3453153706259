import AxiosWithToken from "../global/axiosBase"
import { getPageParams } from "./utils"

export const getTodayShipment = () => AxiosWithToken.get('shipment/')

export const getShipment = (shipmentId) => AxiosWithToken.get(`shipment/${shipmentId}/`)

export const startShipment = (data) => AxiosWithToken.patch('start_shipment/', data)

export const endShipment = (data) => AxiosWithToken.patch('end_shipment/', data)

export const getShipmentDetail = ({ shipmentId }) => { return AxiosWithToken.get(`shipment/${shipmentId}/items/`) }
export const getShipmentOrders = ({ queryKey }) => {
    const [ _, shipmentId ] = queryKey
    return AxiosWithToken.get(`shipment/${shipmentId}/items/`) 
}

export const getCurrentDelmanLocations = () => {
    return AxiosWithToken.get('shipment_location/')
}

export const getShipmentStops = (delmanId = null) => {
    return AxiosWithToken.post('delman_stops/', { delmanId })
}

export const getShipmentRoutes = (delmanId = null) => {
    return AxiosWithToken.post('delman_routes/', { delmanId })
}

export const updateShipmentLocation = (data) => {
    return AxiosWithToken.patch('update_shipment_location/', data)
}

// history
export const getShipments = ({ queryKey }) => {
    const [ _, filters, page, pageSize ] = queryKey
    let url = getPageParams(page, pageSize, 'shipment/history/')
    url = filters?.reduce((acc, cur) => acc += `&${cur.k}=${cur.v}`, url)
    return AxiosWithToken.get(url)
}

// shipment expense
export const getShipmentExpense = (shipmentId) => {
    return AxiosWithToken.get(`shipment/${shipmentId}/expense/`)
}

// get orders to ship
export const getOrdersToShip = () => {
    return AxiosWithToken.get('to_shipment/')
}
export const getOrdersToShipByDelman = ({ queryKey }) => {
    const [ _, delmanId ] = queryKey
    const id = delmanId === 0 ? null : delmanId
    return AxiosWithToken.patch('to_shipment/', { delmanId: id })
}

export const cancelShipment = (shipmentId) => AxiosWithToken.delete(`shipment/${shipmentId}/cancel/`)

export const moveShipmentItem = ({ orderIds, delmanId }) => {
    return AxiosWithToken.patch('move_shipment_item/', { orderIds, delmanId })
}

// change order status (only new or prepared orders) for shipment
export const changeShipmentOrderStatus = (data) => AxiosWithToken.patch('shipment/change_order_status/', data)