import { AdvancedMarker } from '@vis.gl/react-google-maps'
import DelmanLabel from './DelmanLabel'
import { Box } from '@mui/material'
import { useState } from 'react'
import car from './car.png'
import './blinking.css'

export default function Delman({ data }) {
    const { lat, lng, name, progress, ordersCnt, color } = data
    const [open, setOpen] = useState(true)

    if (!lat||!lng) return null
    return (
        <AdvancedMarker position={{ lat, lng }} onClick={() => setOpen(!open)}>
            <Box
                sx={{
                    width: '50px',
                    height: '50px',
                    transform: `translate(5%, 45%)`,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box 
                    className='blink' 
                    sx={{ 
                        border: 3,
                        borderColor: color,
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '50%', left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
                <img
                    src={car}
                    alt='car.png'
                    style={{ 
                        width: '45px', 
                        height: '45px', 
                        position: 'absolute', 
                    }}
                />
                {
                    open && (
                        <DelmanLabel
                            delman={name}
                            progress={`${progress}/${ordersCnt}`}
                            color={color}
                        />
                    )
                }
            </Box>
        </AdvancedMarker>
    )
}
