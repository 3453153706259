import { getCurrentDelmanLocations, getShipmentRoutes, getShipmentStops } from '../../../../../api/shipment'
import { createContext, useContext, useEffect, useState } from 'react'
import { defaultCenter, defaultZoom, maxBounds } from '../utils'
import { useBcCtx } from '../../../../../context/BroadcastCtx'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import {APIProvider, Map } from '@vis.gl/react-google-maps'
import { mapOptions } from '../../../../utils'
import DeliveryRoutes from './DeliveryRoutes'
import DeliveryStops from './DeliveryStops'
import ControlPanel from './ControlPanel'
import { Box } from '@mui/material'
import Delmans from './Delmans'

const boundRestriction = {
    restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
    },
}

const DeliveryMapCtx = createContext(null)
export const useDeliveryMapCtx = () => useContext(DeliveryMapCtx)
export default function DeliveryMap() {
    const [zoom, setZoom] = useState(defaultZoom)
    const [center, setCenter] = useState(defaultCenter)

    const [selectedDelman, setSelectedDelman] = useState(null)
    // delman locations
    const [delmanLocations, setDelmanLocations] = useState([])
    const delmanNames = delmanLocations?.map(dl => ({ id: dl.delmanId, name: dl.name }))
    useEffect(() => {
        document.title = 'Түгээлтийн явц'
        getCurrentDelmanLocations()
            .then(res => {
                if (res?.status === 200 && res?.data) { 
                    setDelmanLocations(res.data)
                }
            })
            .catch(err => { console.log("ERR: ", err) })
    }, [])
    const delmanLocs = selectedDelman ? delmanLocations.filter(cl => cl.delmanId === selectedDelman) : delmanLocations
    const { msg } = useBcCtx()
    useEffect(() => {
        if (msg && msg?.body?.data?.delmanId) {
            setDelmanLocations(prev => (prev.map(cl => msg.body.data.delmanId === cl.delmanId ? msg.body.data : cl)))
        }
    }, [msg])

    const [checked, setChecked] = useState([])
    const [stops, setStops] = useState([])
    const delmanStops = selectedDelman ? stops.filter(s => s.delmanId === selectedDelman) : stops
    //console.log("ST: ", stops)
    const [routes, setRoutes] = useState([])
    const delmanRoutes = selectedDelman ? routes.filter(r => r.delmanId === selectedDelman) : routes

    useEffect(() => {
        if (checked.includes(1)) {
            // get stops
            getShipmentStops(selectedDelman||null)
                .then(res => { setStops(res?.data) })
                .catch(err => { console.log("ERR stops: ", err) })
        } else { setStops([]) }
        if (checked.includes(2)) {
            // get routes
            getShipmentRoutes(selectedDelman||null)
                .then(res => { setRoutes(res?.data) })
                .catch(err => { console.log("ERR routes: ", err) })
        } else { setRoutes([]) }
    }, [checked, selectedDelman])

    const [toast, setToast] = useState(null)
    const toastWarning = () => { setToast({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }

    return (
        <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
            onLoad={() => { console.log('Maps API has loaded.') }}
            onError={(err) => console.error('Maps API loading failed.', err)}
        >
            <DeliveryMapCtx.Provider
                values={{ setToast }}
            >
                <Box sx={{ width: '100%', height: '100vh' }}>
                    {getToast(Boolean(toast), () => setToast(null), toast?.m, toast?.s)}
                    <Map
                        defaultCenter={center}
                        defaultZoom={zoom}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        minZoom={10}
                        maxZoom={18}
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                        options={{ ...mapOptions, ...boundRestriction }}
                    >
                        <ControlPanel
                            checked={checked}
                            setChecked={setChecked}
                            selectDelman={setSelectedDelman}
                            selectedDelman={selectedDelman}
                            delmanNames={delmanNames}
                        />
                        <Delmans delmanLocs={delmanLocs} />
                        <DeliveryRoutes routes={delmanRoutes} checked={checked} />
                        <DeliveryStops
                            stops={delmanStops}
                            delmanLocs={delmanLocs}
                            selectedDelman={selectedDelman}
                        />
                    </Map>
                </Box>
            </DeliveryMapCtx.Provider>
        </APIProvider>
    )
}