import { Box, Button, Tooltip, Typography, Zoom, styled, tooltipClasses } from '@mui/material'
import { PHARMA_ROLES, scrollStyle } from '../../../../../utils/constants'
import { getMarkedPromosBySupplier } from '../../../../../api/promo'
import { isTokenValid } from '../../../../../utils/functions'
import { useAuthCtx } from '../../../../../context/AuthCtx'
import { pickSupplier } from '../../../../../api/auth'
import { ArrowDownward } from '@mui/icons-material'
import MarkedPromos from './MarkedPromos'
import { useQuery } from 'react-query'
import { keys } from 'underscore'
import DropMenu from './DropMenu'
import { useState } from 'react'

const width = '180px'

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} TransitionComponent={Zoom} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 'none',
        boxShadow: theme.shadows[2]
    }
}))

export default function Suppliers({ disabled = false }) {
    const { email, role, supplier } = isTokenValid()
    const isPharm = PHARMA_ROLES?.includes(role)
    const { supplierId, setSupplierId, suppliers } = useAuthCtx()
    
    // marked promos
    const [promos, setPromos] = useState([])
    const { refetch } = useQuery(['get_marked_promos_by_supplier', supplierId], getMarkedPromosBySupplier, { enabled: Boolean(supplierId), onSuccess: (res) => { setPromos(res?.data) } })

    const handleClick = (id) => {
        if (email && isPharm) {
            pickSupplier(id)
                .then(async res => {
                    setSupplierId(res?.data?.id)
                    if (res?.data?.access_token && res?.data?.refresh_token) {
                        window.localStorage.setItem('access_token', res?.data?.access_token)
                        window.localStorage.setItem('refresh_token', res?.data?.refresh_token)
                    }
                })
                .catch(e => { console.log("Pick supplier err: ", e) })
        }
    }

    const getSup = () => {
        if (supplierId) {
            return suppliers?.[supplierId]
        } else {
            if (supplier) {
                const isInc = keys(suppliers).map(k => parseInt(k)).includes(supplier)
                return isInc ? suppliers?.[supplier] : 'Сонгох'
            }
            return 'Сонгох'
        }
    }

    return (
        <Box>
            <DropMenu
                title={
                    <Button
                        sx={{
                            width, color: 'white',
                            borderRadius: 0, p: 0,
                            backgroundColor: 'secondary.light',
                            '&:hover': { backgroundColor: 'secondary.light' },
                        }}
                        size='small'
                    >
                        <Typography noWrap sx={{ fontSize: '14px' }}>{getSup()}</Typography>
                        {isPharm ? <ArrowDownward color='white' sx={{ fontSize: '15px', ml: 1 }} /> : null}
                    </Button>
                }
                detail={
                    isPharm
                        ? <Box
                            sx={{
                                display: 'flex', flexDirection: 'column', p: 0, m: 0, backgroundColor: 'white',
                                maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden', boxShadow: 3, border: 1,
                                ...scrollStyle, '&::-webkit-scrollbar': { width: 5 }, visibility: disabled ? 'hidden' : 'visible'
                            }}
                        >
                            {keys(suppliers).map(k => ( // .filter(s => s !== supplierId)
                                <Button
                                    key={`${k}`}
                                    onClick={() => { handleClick(k) }}
                                    sx={{
                                        width,
                                        borderBottom: '1px solid transparent',
                                        borderRadius: 0,
                                        '&:hover': { borderBottom: '1px solid #ad5555' },
                                        display: 'flex', justifyContent: 'left',
                                        pl: 1
                                    }}
                                >
                                    <Typography noWrap fontSize='14px'>{suppliers[k]}</Typography>
                                </Button>
                            ))}
                        </Box>
                        : null
                }
            />
            {promos?.length > 0 && <MarkedPromos promos={promos} setPromos={setPromos} refetch={refetch} />}
        </Box>
    )
}
