import { selectDelman } from '../../../../../../../../../api/staff'
import { Box, MenuItem } from '@mui/material'
import { useAllocationCtx } from '../../..'

export default function ContextMenuBox({ handleCmbClose, orderIds }) {
    const { delmans, setMsg, alertWarning, delmanId, refetchDelmanStops, refetchAllStops, setSelected, setToSelect } = useAllocationCtx()
    const handleSelectDelman = (staffId) => {
        if (orderIds?.length > 0 && staffId) {
            selectDelman({ orderIds, staffId })
                .then(_ => {
                    setMsg({ m: 'Амжилттай!', s: 'success' })
                    handleCmbClose()
                    setSelected([])
                    setToSelect(false)
                    delmanId ? refetchDelmanStops() : refetchAllStops()
                })
                .catch(error => {
                    const isShipStarted = error?.response?.data?.order?.[0] === "order on the way!"
                    if (isShipStarted) { setMsg({ m: 'Түгээлт эхэлсэн байна!', s: 'warning' }) }
                    else { alertWarning() }
                    console.log("E: ", error)
                })
                .finally(() => handleCmbClose())
        }
    }
    const handleSelect = (e, id) => { handleSelectDelman(id); e.stopPropagation() }

    const ds = delmanId ? delmans?.filter(d => d.id !== delmanId) : delmans

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 2px 10px #000',
                borderRadius: '5px',
                bgcolor: 'white',
                width: 'fit-content',
                p: '2px'
            }}
        >
            {
                ds?.map(d => (
                    <MenuItem 
                        key={d.id}
                        onClick={e => handleSelect(e, d.id)}
                        sx={{ 
                            bgcolor: 'white', color: 'primary.light', fontSize: '14px',
                            '&:hover': { bgcolor: 'primary.light', color: 'white' },
                            transition: 'ease-in 0.15s', borderRadius: '5px'

                        }}
                    >{d.name||''}</MenuItem>
                ))
            }
        </Box>
    )
}
